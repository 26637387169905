
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  props: {},
  components: { Breadcrumbs },
})
export default class PersonalDetails extends Vue {
  zzlApi: any;
  condition: any = { idNumber: "" };
  columnFields: any = [];

  tableConfig: any = {
    member: {
      title: "人员信息",
      fields: [],
      list: [],
      config: {}
    },
    memberCompany: {
      title: "从业轨迹",
      fields: [],
      list: [],
      config: {}
    },
    memberPracticeCertRecord: {
      title: "执业证记录",
      fields: [],
      list: [],
      config: {}
    },
    summaryMemberCompany: {
      title: "从业轨迹中间表",
      fields: [],
      list: [],
      config: {
        status: { option: [{ value: "F", label: "正常" }, { value: "L", label: "失效" }] },
      }
    },
    creditV2Log: {
      title: "诚信记录(新)",
      fields: [],
      list: [],
      config: {}
    },
    creditLog: {
      title: "诚信记录(旧)",
      fields: [],
      list: [],
      config: {}
    },
    creditChangeLog: {
      title: "诚信分数变更记录",
      fields: [],
      list: [],
      config: {}
    },
    creditClassLog: {
      title: "诚信级别记录",
      fields: [],
      list: [],
      config: {}
    },
    memberTraining: {
      title: "培训记录",
      fields: [],
      list: [],
      config: {
        status: { option: [{ value: "A", label: "进行中" }, { value: "F", label: "已达标" }] },
      }
    },
    memberBusinessIndicators: {
      title: "业务指标-离职导入",
      fields: [],
      list: [],
      config: {
        status: { option: [] },
      }
    },
    memberCompanyAchievement: {
      title: "业务指标-在职导入",
      fields: [],
      list: [],
      config: {
        status: { option: [] },
      }
    },
    memberRecognizance: {
      title: "承诺书",
      fields: [],
      list: [],
      config: {}
    },
    memberQualification: {
      title: "资质信息",
      fields: [],
      list: [],
      config: {
        status: { option: [{ value: "A", label: "有效状态" }, { value: "F", label: "无效状态" }] },
      }
    },
    memberQualificationRecord: {
      title: "资质变更记录",
      fields: [],
      list: [],
      config: {
        changeType: { option: [{ value: "1", label: "常规升级" }, { value: "2", label: "一般失信降级" }, { value: "3", label: "严重失信降级" }] },
      }
    },
    memberRelative: {
      title: "亲属信息",
      fields: [],
      list: [],
      config: {}
    },
    termination: {
      title: "被解约信息",
      fields: [],
      list: [],
      config: {}
    },
    creditV2ChangeLog: {
      title: "诚信分数变更记录(新)",
      fields: [],
      list: [],
      config: {}
    },
    creditV2ClassLog: {
      title: "诚信级别记录(新)",
      fields: [],
      list: [],
      config: {}
    },
    
  };
  creditV2ChangeLogModelIdList:any = [];
  creditV2ClassLogModelIdList:any = [];
  resetCondition() { }

  async clearAll() {
    if (!this.condition.idNumber) {
      this.$message.warning("证件号码不能为空");
      return;
    }
    this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      const loading = this.$loading({lock: true,text: '删除中',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
      const res = await this.zzlApi.member.DeleteMember({ idnumber: this.condition.idNumber });
      loading.close();
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      } else {
        this.$message({
          type: 'success',
          message: res.msg
        });
      }
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '删除失败'
      });
    });
  }
  async getData() {
    if (!this.condition.idNumber) {
      this.$message.warning("证件号码不能为空");
      return;
    }
    const res = await this.zzlApi.member.Infos(
      this.condition,
      true
      );
    this.tableConfig.member.list = [];
    if (res.code === 0 && res.data) {
      console.log(111)
      for (let key in res.data) {
        // if (key !== "creditV2ChangeLog" && key !== "creditV2ClassLog") {
          this.tableConfig[key].list = res.data[key];
          if (res.data[key].length > 0)
          this.tableConfig[key].fields = Object.keys(res.data[key][0]);
        // }
    }
    }
  }
  getCell(ev: any) {
    let parentNode: any = ev.target.parentNode;
    while (parentNode && !parentNode.classList.contains("cell")) {
      parentNode = parentNode.parentNode;
    }
    return parentNode;
  }
  editAction(ev: any, row: any, field: string) {
    this.$set(row, `${field}Edit`, true);
    this.$set(row, "isEdit", true);
  }
  async saveAction(row: any, keyName: string) {
    // if(!row.isEdit) return;
    let res = await this.zzlApi[keyName.toLowerCase()].Update({ ...row });
    if (res.code == 0) {
      //   this.$set(row,"isEdit",false);
      // for(let field in row){
      //     if(row[`${field}Edit`])
      //     row[`${field}Edit`]=false;
      // }
      this.$message.success("更新成功");
    } else {
      this.$message.error("更新失败");
    }
  }
  async deleteAction(row: any, keyName: any,type:any) {
  this.creditV2ClassLogModelIdList= []; //新版诚信级别记录删除Id
  this.creditV2ChangeLogModelIdList= []; //新版诚信分数变更记录删除Id
    let ids: any = [];
    let deleteIds:any [];
    let deleteIds2:any [];
    if (!row) {
      ids = (this.$refs[`${keyName}Ref`] as any)[0].selection.map((m: any) => {
         deleteIds = m.creditV2ChangeLogModelIdList;
         deleteIds2 = m.creditV2ClassLogModelIdList;
        return m.id;
      });
    }else ids.push(row.id);
    if (!ids.length) {
      this.$message.warning("请至少选择一条数据");
      return;
    }
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        if(type === 'all') {
          ids.map((item,index) => {
            if(this.tableConfig[keyName].list) {
              this.tableConfig[keyName].list.map((item2,index2) => {
                if(item === item2.id) {
                  this.creditV2ClassLogModelIdList.push(...item2.creditV2ClassLogModelIdList || [])
                  this.creditV2ChangeLogModelIdList.push(...item.creditV2ChangeLogModelIdList || [])
                }
              })
            }
          })
        }else{
          this.creditV2ChangeLogModelIdList = deleteIds || [];
          this.creditV2ClassLogModelIdList = deleteIds2 || [];
        }
        let params:any = {};
        if(keyName === 'creditV2Log') {
          params = {
            ids,
            creditV2ChangeLogModelIdList:this.creditV2ChangeLogModelIdList || [],
            creditV2ClassLogModelIdList: this.creditV2ClassLogModelIdList || []
          }
        }else{
          params = {
            ids
          }
        }
        let res = await this.zzlApi[keyName.toLowerCase()].BulkDelete(params);
        if (res.code == 0) {
          this.$message.success("删除成功");

        } else {
          this.$message.error("删除失败");
        }
      })
      .catch(() => {
        this.$message.info("已取消");
      });
  }
  @Watch("$store.state.stateStatus")
  async initData() {
    if (this.$store.state.stateStatus) {
      if (this.$store.state.sex) {
        console.log(12138);
        this.tableConfig.memberBusinessIndicators.config.status.option = this.$store.state.businessOperatorStatus
        this.columnFields = (
          (await import("./PersonalDetailsConfig")) as any
        ).default;
      }
    }
  }
  mounted() {
    this.initData();
  }
}
